import { Plugins } from '@capacitor/core';

const { Storage } = Plugins;

export const getStoredDataObject = async (key: string) => {
	const ret = await Storage.get({ key });
	if (ret && ret.value !== null) {
		return JSON.parse(ret.value);
	}
};

export const storeDataObject = async (key: string, value: any) => {
	await Storage.set({
		key,
		value: JSON.stringify(value),
	});
};
