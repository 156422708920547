import classNames from 'classnames';
import React from 'react';
import style from './moduleHeading.module.scss';

interface ModuleHeadingProps {
	title: string;
	color?: 'primary' | 'secondary' | 'tertiary';
}

export const ModuleHeading: React.FunctionComponent<ModuleHeadingProps> = (
	props: ModuleHeadingProps
) => {
	return (
		<h1
			className={classNames(style.heading, {
				[style.heading__primary]: props.color === 'primary',
				[style.heading__secondary]: props.color === 'secondary',
				[style.heading__tertiary]: props.color === 'tertiary',
			})}>
			{props.title}
		</h1>
	);
};
