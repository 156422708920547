import React, { useCallback, useMemo, useState } from 'react';
import { EventTile } from '../../components/eventTile/eventTile';
import { Tile } from '../../components/tile/tile';
import { ModuleHeading } from '../../components/moduleHeading/moduleHeading';
import SwiperCore, { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';
import { useApi } from '../../services/apiConnector.hooks';
import { EventCategories, Person } from '../../services/apiTypes';
import { ApiRessource, getImageUrl } from '../../services/apiConnector';
import dayjs from 'dayjs';
import i18next from 'i18next';
import { Suspension } from '../../services/apiTypes';
import { EventSlider } from '../../components/eventSlider/eventSlider';
import style from '../responive.module.scss';
import PullToRefresh from 'react-simple-pull-to-refresh';

SwiperCore.use([Navigation]);

export const TennisschulePage: React.FunctionComponent = () => {
	const [refreshCounter, setRefreshCounter] = useState(0);
	const categoryFilter = useMemo(() => {
		return [{ key: 'kategorie', value: EventCategories.Tennisschule }];
	}, []);

	const suspensionFilter = useMemo(
		() => [
			{ key: 'ende_gt', value: dayjs().add(-1, 'day').format('YYYY-MM-DD') },
		],
		[]
	);
	const {
		ressource: suspensionRessources,
		isLoading: isSuspensionLoading,
		refresh: refreshSuspensions,
	} = useApi<Suspension[]>(ApiRessource.TrainingSuspensions, suspensionFilter);

	const {
		ressource: trainerRessources,
		isLoading: isTrainerLoading,
		refresh: refreshTrainer,
	} = useApi<Person[]>(ApiRessource.People, categoryFilter);

	const handleRefresh = useCallback(async () => {
		let promiseResolver: (value?: unknown) => void;
		const refreshPromise = new Promise((resolve, reject) => {
			promiseResolver = resolve;
		});

		await refreshTrainer();
		await refreshSuspensions();
		setRefreshCounter(refreshCounter + 1);
		promiseResolver!();

		return refreshPromise;
	}, [refreshCounter]);

	return (
		<PullToRefresh onRefresh={handleRefresh}>
			<div className={style.maxWidth}>
				{isTrainerLoading ? (
					<Tile title='' image='' subtitle='' loading={true} />
				) : (
					trainerRessources && (
						<>
							<Swiper navigation spaceBetween={50} slidesPerView={1}>
								{trainerRessources.map((item: Person) => (
									<SwiperSlide key={item.id}>
										<Tile
											title={item.name}
											image={getImageUrl(item.profilbild)}
											subtitle={item.beschreibung}
										/>
									</SwiperSlide>
								))}
							</Swiper>
						</>
					)
				)}

				<ModuleHeading title={i18next.t('events.title')} />
				<EventSlider
					category={EventCategories.Tennisschule}
					refreshCounter={refreshCounter}
				/>
				<ModuleHeading title={i18next.t('suspensions.title')} />
				{isSuspensionLoading ? (
					<EventTile title='' subtitle='' loading={true} />
				) : (
					<Swiper navigation spaceBetween={50} slidesPerView={1}>
						{suspensionRessources && suspensionRessources.length > 0 ? (
							suspensionRessources.map((item: Suspension) => (
								<SwiperSlide key={item.id}>
									<EventTile
										title={item.bezeichnung}
										subtitle={`${dayjs(item.start).format(
											'DD.MM.YYYY'
										)} - ${dayjs(item.ende).format('DD.MM.YYYY')}`}
									/>
								</SwiperSlide>
							))
						) : (
							<SwiperSlide>
								<EventTile
									title={i18next.t('suspensions.none')}
									subtitle={i18next.t('suspensions.noneSub')}
								/>
							</SwiperSlide>
						)}
					</Swiper>
				)}
			</div>
		</PullToRefresh>
	);
};
