import { MapItemType } from './map';

export interface MenuItem {
	titleId: string;
	target: string;
	children?: {
		titleId: string;
		target: string;
	}[];
}

export enum MenuType {
	MainMenu,
	SubMenu,
	LegalMenu,
}

export const MainMenu: MenuItem[] = [
	{
		titleId: 'home',
		target: '/home',
	},
	{
		titleId: 'news',
		target: '/news',
		children: [
			{
				titleId: 'alle',
				target: '/news',
			},
			{
				titleId: 'aktive',
				target: '/news/aktive',
			},
			{
				titleId: 'senioren',
				target: '/news/senioren',
			},
			{
				titleId: 'jugend',
				target: '/news/jugend',
			},
		],
	},
	{
		titleId: 'mannschaften',
		target: '/mannschaften',
		children: [
			{
				titleId: 'herren',
				target: '/mannschaften/herren',
			},
			{
				titleId: 'damen',
				target: '/mannschaften/damen',
			},
			{
				titleId: 'jugend',
				target: '/mannschaften/jugend',
			},
		],
	},
	{
		titleId: 'anlage',
		target: '/anlage',
		children: [
			{
				titleId: 'alle',
				target: `/anlage`,
			},
			{
				titleId: 'playground',
				target: `/anlage/${MapItemType.Fun}`,
			},
			{
				titleId: 'courts',
				target: `/anlage/${MapItemType.Court}`,
			},
			{
				titleId: 'fitness',
				target: `/anlage/${MapItemType.Fitness}`,
			},
			{
				titleId: 'clubhouse',
				target: `/anlage/${MapItemType.Clubhouse}`,
			},
		],
	},
	{
		titleId: 'tennisschule',
		target: '/tennisschule',
	},
	{
		titleId: 'restaurant',
		target: '/restaurant',
	},
];

export const LegalMenu: MenuItem[] = [
	{
		titleId: 'datenschutz',
		target: '/datenschutz',
	},
	{
		titleId: 'impressum',
		target: '/impressum',
	},
];
