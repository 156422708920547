import { put, takeEvery } from 'redux-saga/effects';
import { ApiRessource, getApiRessource } from '../services/apiConnector';
import { setSettingsAction } from './actions/settings';
import { Settings } from '../services/apiTypes';
import { SettingsState } from './types/state';
import { getInitialMenuItem } from '../utils/navigation';
import { InitializeApplicationAction } from './actions/general';
import { setCurrentMenuItemAction } from './actions/navigation';

function* initializeApplication(action: InitializeApplicationAction) {
	const settings: Settings[] | undefined = yield getApiRessource<Settings[]>(
		ApiRessource.Settings,
		[],
		[]
	);

	if (settings) {
		const payload: SettingsState = {};
		for (const setting of settings) {
			payload[setting.key] = setting.value;
		}
		yield put(setSettingsAction(payload));
	}

	const initialMenuItem = getInitialMenuItem(action.payload.pathname);
	yield put(
		setCurrentMenuItemAction(initialMenuItem.id, initialMenuItem.menuType)
	);
}

/*
   Starts fetchUser on each dispatched `USER_FETCH_REQUESTED` action.
   Allows concurrent fetches of user.
 */
export function* appSaga() {
	yield takeEvery('initializeApplicationAction', initializeApplication);
}
