interface StrapiMediaFormat {
	name: string;
	hash: string;
	ext: string;
	mime: string;
	width: number;
	height: number;
	size: number;
	path: string;
	url: string;
}

export interface StrapiMedia {
	id: number;
	name: string;
	alternativeText: string;
	caption: string;
	width: number;
	height: number;
	formats: {
		thumbnail: StrapiMediaFormat;
		large: StrapiMediaFormat;
		medium: StrapiMediaFormat;
		small: StrapiMediaFormat;
	};
	hash: string;
	ext: string;
	mime: string;
	size: number;
	url: string;
	previewUrl: string;
	provider: string;
	provider_metadata: any;
	created_at: Date;
	updated_at: Date;
}

export enum TeamCategories {
	Jugend = 'Jugend',
	Herren = 'Herren',
	Damen = 'Damen',
}

export interface Team {
	id: number;
	bezeichnung: string;
	spielgruppe: number;
	leader: string;
	liga: string;
	kategorie: TeamCategories;
	order: null;
	published_at: Date;
	created_at: Date;
	updated_at: Date;
	mannschaftsfoto: StrapiMedia;
}

export interface Settings {
	id: number;
	key: string;
	value: string;
	published_at: Date;
	created_at: Date;
	updated_at: Date;
}

export enum EventCategories {
	Allgemein = 'Allgemein',
	Restaurant = 'Restaurant',
	Tennisschule = 'Tennisschule',
}

export interface Event {
	id: number;
	titel: string;
	beginn: Date;
	ende: Date;
	link: string | null;
	published_at: Date;
	created_at: Date;
	updated_at: Date;
}

export enum PersonCategories {
	Vorstandschaft = 'Vorstandschaft',
	Restaurant = 'Restaurant',
	Tennisschule = 'Tennisschule',
}

export interface Person {
	id: number;
	name: string;
	beschreibung: string;
	kategorie: PersonCategories;
	published_at: Date;
	created_at: Date;
	updated_at: Date;
	profilbild: StrapiMedia;
}

export interface Suspension {
	id: number;
	bezeichnung: string;
	start: Date;
	ende: Date;
	published_at: Date;
	created_at: Date;
	updated_at: Date;
}

export enum Days {
	Montag = 'Montag',
	Dienstag = 'Dienstag',
	Mittwoch = 'Mittwoch',
	Donnerstag = 'Donnerstag',
	Freitag = 'Freitag',
	Samstag = 'Samstag',
	Sonntag = 'Sonntag',
}

export interface OpeningHour {
	id: number;
	tag: Days;
	start: string;
	ende: string;
	kategorie: 'Tennisschule' | 'Restaurant';
	offen: boolean;
	published_at: Date;
	created_at: Date;
	updated_at: Date;
}

export interface OSVisitors {
	id: number;
	name: string;
	email: string;
	telefon: string;
	published_at: Date;
	created_at: Date;
	updated_at: Date;
}

export interface OSAreas {
	id: number;
	name: string;
	order: number;
	published_at: Date;
	created_at: Date;
	updated_at: Date;
}
