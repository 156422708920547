import classNames from 'classnames';
import React from 'react';
import style from './quicklink.module.scss';
import { ReactComponent as Ball } from '../../assets/ball.svg';

interface QuicklinkProps {
	title: string;
	subtitle?: string;
	onClick: () => void;
	type?: 'primary' | 'secondary' | 'tertiary' | 'error';
	loading?: boolean;
	disabled?: boolean;
	size?: 'small' | 'regular';
}

export const Quicklink: React.FunctionComponent<QuicklinkProps> = (
	props: QuicklinkProps
) => {
	return (
		<div
			className={classNames(style.container, {
				[style.container__primary]: props.type === 'primary',
				[style.container__secondary]: props.type === 'secondary',
				[style.container__tertiary]: props.type === 'tertiary',
				[style.container__error]: props.type === 'error',
				[style.disabled]: props.disabled,
				[style.container__small]: props.size === 'small',
			})}
			onClick={!props.disabled ? props.onClick : undefined}>
			{props.loading ? <Ball className={style.loader} /> : props.title}
			{props.subtitle && (
				<span className={style.subtitle}>{props.subtitle}</span>
			)}
		</div>
	);
};
