import React, { useCallback, useMemo, useState } from 'react';
import { EventTile } from '../../components/eventTile/eventTile';
import { Tile } from '../../components/tile/tile';
import { ModuleHeading } from '../../components/moduleHeading/moduleHeading';
import SwiperCore, { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';
import { useApi } from '../../services/apiConnector.hooks';
import { EventCategories, Person } from '../../services/apiTypes';
import { ApiRessource, getImageUrl } from '../../services/apiConnector';
import i18next from 'i18next';
import { OpeningHour } from '../../services/apiTypes';
import { formatTime } from '../../utils/format';
import { EventSlider } from '../../components/eventSlider/eventSlider';
import style from '../responive.module.scss';
import PullToRefresh from 'react-simple-pull-to-refresh';

SwiperCore.use([Navigation]);

export const RestaurantPage: React.FunctionComponent = () => {
	const categoryFilter = useMemo(
		() => [{ key: 'kategorie', value: EventCategories.Restaurant }],
		[]
	);
	const [refreshCounter, setRefreshCounter] = useState(0);

	const {
		ressource: openingRessources,
		isLoading: isOpeningLoading,
		refresh: refreshOpeningHours,
	} = useApi<OpeningHour[]>(ApiRessource.OpeningHours, categoryFilter);

	const peopleApi = useApi<Person[]>(ApiRessource.People, categoryFilter);
	const {
		ressource: peopleRessources,
		isLoading: isPeopleLoading,
		refresh: refreshPeople,
	} = peopleApi;

	const handleRefresh = useCallback(async () => {
		let promiseResolver: (value?: unknown) => void;
		const refreshPromise = new Promise((resolve, reject) => {
			promiseResolver = resolve;
		});

		await refreshPeople();
		await refreshOpeningHours();
		setRefreshCounter(refreshCounter + 1);
		promiseResolver!();

		return refreshPromise;
	}, [refreshCounter]);

	return (
		<PullToRefresh onRefresh={handleRefresh}>
			<div className={style.maxWidth}>
				{isPeopleLoading ? (
					<Tile title='' image='' subtitle='' loading={true} />
				) : (
					peopleRessources && (
						<>
							<Swiper navigation spaceBetween={50} slidesPerView={1}>
								{peopleRessources.map((item: Person) => (
									<SwiperSlide key={item.id}>
										<Tile
											title={item.name}
											image={getImageUrl(item.profilbild)}
											subtitle={item.beschreibung}
										/>
									</SwiperSlide>
								))}
							</Swiper>
						</>
					)
				)}

				<ModuleHeading title={i18next.t('events.title')} />
				<EventSlider
					category={EventCategories.Restaurant}
					refreshCounter={refreshCounter}
				/>
				<ModuleHeading title={i18next.t('openingHours.title')} />
				{isOpeningLoading ? (
					<EventTile title='' subtitle='' loading={true} />
				) : (
					<Swiper navigation spaceBetween={50} slidesPerView={1}>
						{openingRessources && openingRessources.length > 0 ? (
							openingRessources.map((item: OpeningHour) => (
								<SwiperSlide key={item.id}>
									<EventTile
										subtitle={item.tag}
										title={
											item.offen
												? `${formatTime(item.start)} - ${formatTime(item.ende)}`
												: i18next.t('openingHours.closed')
										}
									/>
								</SwiperSlide>
							))
						) : (
							<SwiperSlide>
								<EventTile
									title={i18next.t('openingHours.none')}
									subtitle={i18next.t('openingHours.noneSub')}
								/>
							</SwiperSlide>
						)}
					</Swiper>
				)}
			</div>
		</PullToRefresh>
	);
};
