import React from 'react';
import { ReactComponent as Logo } from '../../assets/logo.svg';
import style from './nothingHere.module.scss';

export const NothingHere: React.FunctionComponent = () => {
	return (
		<div className={style.container}>
			<Logo className={style.logo} />
			<h1 className={style.heading}>Nichts los hier</h1>
			<span>
				Bitte schauen sie später nochmal rein, wir konnten gerade keine Inhalte
				finden.
			</span>
		</div>
	);
};
