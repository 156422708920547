export const getFeaturedImageUrl = (post: any) => {
	try {
		const path = post['_embedded']['wp:featuredmedia'][0].media_details.sizes;
		const featuredImage = path.large
			? path.large.source_url
			: path.medium.source_url;
		return featuredImage;
	} catch (e) {
		console.warn('No featured image for this Post');
	}
	return undefined;
};

export const getNextPage = (link: string | null): string | undefined => {
	const regex = /<(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})>; rel="next"/im;
	let m;

	if (link && (m = regex.exec(link)) !== null) {
		return m[1];
	}
};
