import React from 'react';
import style from './developedBy.module.scss';
import logo from '../../assets/logo.svg';
import cpLogo from '../../assets/checknplay_white.svg';

export const DevelopedBy: React.FunctionComponent = () => {
	return (
		<div className={style.developedBy}>
			<img
				src={logo}
				alt='TC Schönbusch Aschaffenburg e.V.'
				className={style.club}
			/>
			<img src={cpLogo} alt="check'n Play" className={style.developer} />
		</div>
	);
};
