import classNames from 'classnames';
import React from 'react';
import style from './articleContent.module.scss';

interface ArticleContentProps {
	title?: string;
	subtitle?: string;
	content: string;
	loading?: boolean;
}

export const ArticleContent: React.FunctionComponent<ArticleContentProps> = (
	props: ArticleContentProps
) => {
	return (
		<div
			className={classNames(style.container, {
				[style.loading]: props.loading,
			})}>
			{props.subtitle && (
				<span className={style.subHeading}>{props.subtitle}</span>
			)}
			{props.title && (
				<h1
					className={style.heading}
					dangerouslySetInnerHTML={{ __html: props.title }}
				/>
			)}
			<div
				className={style.content}
				dangerouslySetInnerHTML={{ __html: props.content }}
			/>
			{props.loading && (
				<div className={style.content}>
					<div className={style.line} />
					<div className={style.line} />
					<div className={style.line} />
					<div className={style.line} />
					<div className={style.line} />
					<div className={style.line} />
					<div className={classNames(style.line, style.last)} />
				</div>
			)}
		</div>
	);
};
