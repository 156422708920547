import i18next from 'i18next';
import { MenuItem } from '../definitions/menu';

export const getPageTitle = (menuItem: MenuItem) => {
	return i18next.t(`pageTitle.${menuItem.titleId}`);
};
export const getMenuTitle = (menuItem: MenuItem, isSubMenu = false) => {
	return i18next.t(
		`menuTitle.${isSubMenu ? 'subMenu.' : ''}${menuItem.titleId}`
	);
};
