import React, { useCallback, useMemo } from 'react';
import { Redirect, useParams } from 'react-router';
import { useApi } from '../../services/apiConnector.hooks';
import { ApiRessource, getImageUrl } from '../../services/apiConnector';
import { TeamCategories, Team } from '../../services/apiTypes';
import { Tile } from '../../components/tile/tile';
import { openUrl } from '../../utils/navigation';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../redux/types/state';
import { NothingHere } from '../../components/nothingHere/nothingHere';
import style from '../responive.module.scss';
import PullToRefresh from 'react-simple-pull-to-refresh';

export const TeamsPage: React.FunctionComponent = () => {
	const { category } = useParams<{ category: string }>();
	const btvUri = useSelector(
		(state: ApplicationState) => state.settings.btvGroupUri
	);

	const teamSortParameter = useMemo(() => [{ key: 'order', value: 'ASC' }], []);
	const teamCategoryFilter = useMemo(() => {
		switch (category?.toLowerCase()) {
			case 'jugend':
				return [{ key: 'kategorie', value: TeamCategories.Jugend }];
			case 'damen':
				return [{ key: 'kategorie', value: TeamCategories.Damen }];
			case 'herren':
			default:
				return [{ key: 'kategorie', value: TeamCategories.Herren }];
		}
	}, [category]);

	const { isLoading, ressource, refresh } = useApi<Team[]>(
		ApiRessource.Teams,
		teamCategoryFilter,
		teamSortParameter
	);

	const handleRefresh = useCallback(async () => {
		let promiseResolver: (value?: unknown) => void;
		const refreshPromise = new Promise((resolve, reject) => {
			promiseResolver = resolve;
		});

		await refresh();
		promiseResolver!();

		return refreshPromise;
	}, []);

	return (
		<PullToRefresh onRefresh={handleRefresh}>
			<>
				{!category && <Redirect to={'/mannschaften/herren'} />}
				{isLoading ? (
					<div className={style.grid}>
						<Tile
							title=''
							subtitle=''
							image=''
							onClick={() => {}}
							loading={true}
						/>
					</div>
				) : !ressource || ressource.length === 0 ? (
					<NothingHere />
				) : (
					<div className={style.grid}>
						{ressource.map((item: Team) => {
							return (
								<Tile
									key={item.id}
									title={item.bezeichnung}
									subtitle={item.liga}
									image={getImageUrl(item.mannschaftsfoto)}
									onClick={() => openUrl(`${btvUri}${item.spielgruppe}`)}
								/>
							);
						})}
					</div>
				)}
			</>
		</PullToRefresh>
	);
};
