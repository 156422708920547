import '@capacitor/core';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import { Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import german from './language/de-de.json';
import i18next from 'i18next';
import { history } from './definitions/history';
import { defineCustomElements } from '@ionic/pwa-elements/loader';

i18next.init({
	lng: 'de',
	debug: true,
	resources: {
		de: { translation: german },
	},
});

ReactDOM.render(
	<React.StrictMode>
		<Provider store={store}>
			<Router history={history}>
				<App />
			</Router>
		</Provider>
	</React.StrictMode>,
	document.getElementById('root')
);

defineCustomElements(window);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
