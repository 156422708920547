import { SettingsState } from '../types/state';
import { SettingsActions } from '../actions/settings';

export const initSettingsState: SettingsState = {};

export const settingsReducer = (
	state: SettingsState = initSettingsState,
	action: SettingsActions
) => {
	switch (action.type) {
		case 'setSettingsAction':
			return action.payload;
		default:
			return state;
	}
};
