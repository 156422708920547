import { history } from '../definitions/history';
import { LegalMenu, MainMenu, MenuItem, MenuType } from '../definitions/menu';
import { Plugins } from '@capacitor/core';
const { Browser } = Plugins;

export const navigate = (target: string) => {
	history.push(target);
};

export const openUrl = async (url: string) => {
	await Browser.open({ url });
};

export const getInitialMenuItem = (pathname: string) => {
	const mainMenuItem = searchItem(pathname, MainMenu, MenuType.MainMenu);
	if (mainMenuItem) {
		return mainMenuItem;
	}
	const legalMenuItem = searchItem(pathname, LegalMenu, MenuType.LegalMenu);
	if (legalMenuItem) {
		return legalMenuItem;
	}

	return getCurrentMenuItemResult(0, MenuType.MainMenu);
};

const searchItem = (
	pathname: string,
	menuItems: MenuItem[],
	menuType: MenuType
) => {
	let id = 0;
	for (const menuItem of menuItems) {
		if (pathname.includes(menuItem.target)) {
			return getCurrentMenuItemResult(id, menuType);
		}
		id++;
	}
};

const getCurrentMenuItemResult = (id: number, menuType: MenuType) => {
	return { id, menuType };
};
