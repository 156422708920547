import React, { useMemo } from 'react';
import style from './pageWrapper.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import {
	LegalMenu,
	MainMenu,
	MenuType,
	MenuItem,
} from '../../definitions/menu';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../redux/types/state';
import { switchMenuStateAction } from '../../redux/actions/navigation';
import { getMenuTitle, getPageTitle } from '../../utils/pageTitle';
import { navigate } from '../../utils/navigation';
import classNames from 'classnames';
import { useLocation } from 'react-router';
import { DevelopedBy } from '../developedBy/developedBy';

interface PageWrapperProps {
	title: string;
	children: React.ReactNodeArray | React.ReactNode;
}

export const PageWrapper: React.FunctionComponent<PageWrapperProps> = (
	props: PageWrapperProps
) => {
	const location = useLocation();

	const { currentMenuItem, currentMenuItemType } = useSelector(
		(state: ApplicationState) => state.navigation
	);
	const dispatch = useDispatch();

	const currentMainMenu = useMemo(() => {
		if (currentMenuItemType === MenuType.LegalMenu) {
			return LegalMenu[currentMenuItem];
		}
		return MainMenu[currentMenuItem];
	}, [currentMenuItem]);

	const pageTitle = useMemo(() => getPageTitle(currentMainMenu), [
		currentMainMenu,
	]);

	return (
		<div className={style.wrapper}>
			<header className={style.header}>
				<div className={style.topLine}>
					<FontAwesomeIcon
						icon={faBars}
						className={style.icon}
						onClick={() => dispatch(switchMenuStateAction())}
					/>
					<span className={style.pageTitle}>{pageTitle}</span>
				</div>
				{currentMainMenu?.children && (
					<ul className={style.subMenu}>
						{currentMainMenu?.children.map((item: MenuItem) => (
							<li
								key={`sub-${item.titleId}-index`}
								onClick={() => navigate(item.target)}
								className={classNames(style.item, {
									[style.active]: location.pathname === item.target,
								})}>
								{getMenuTitle(item, true)}
							</li>
						))}
					</ul>
				)}
			</header>
			<div
				className={classNames(style.content, {
					[style.withSubMenu]: currentMainMenu?.children,
				})}>
				{props.children}
			</div>
			<DevelopedBy />
		</div>
	);
};
