import React, { useCallback, useEffect } from 'react';
import { useParams } from 'react-router';
import { Tile } from '../../components/tile/tile';
import { RequestType } from '../../services/homepageConnect';
import { useHomepageConnect } from '../../services/homepageConnect.hooks';
import { getFeaturedImageUrl } from '../../utils/wpPostUtils';
import { NothingHere } from '../../components/nothingHere/nothingHere';
import { WordPressPost } from '../../services/homepageTypes';
import style from '../responive.module.scss';
import { useDispatch } from 'react-redux';
import { openPostAction } from '../../redux/actions/post';
import PullToRefresh from 'react-simple-pull-to-refresh';
import classNames from 'classnames';
import { Quicklink } from '../../components/quicklink/quicklink';
import i18next from 'i18next';

enum NewsCategories {
	Allgemein = 1,
	News = 3,
	Jugend = 4,
	Aktive = 5,
	Senioren = 6,
}

export const NewsPage: React.FunctionComponent = () => {
	const { category } = useParams<{ category: string }>();
	const dispatch = useDispatch();

	const getCategoryId = useCallback(() => {
		switch (category) {
			case 'jugend':
				return NewsCategories.Jugend;
			case 'aktive':
				return NewsCategories.Aktive;
			case 'senioren':
				return NewsCategories.Senioren;
			default:
				return undefined;
		}
	}, [category]);

	const {
		ressource,
		getRessource,
		isLoading,
		isLoadingMore,
		canLoadMore,
		loadMore,
	} = useHomepageConnect(RequestType.Post, undefined, 6, getCategoryId());

	useEffect(() => {
		getRessource();
	}, [category]);

	const openPost = useCallback(
		(post: WordPressPost) => dispatch(openPostAction(post)),
		[]
	);

	const handleRefresh = useCallback(async () => {
		return getRessource();
	}, []);

	return (
		<PullToRefresh onRefresh={handleRefresh}>
			{isLoading ? (
				<div className={classNames(style.grid, style.grid__loading)}>
					<Tile title='' image='' onClick={() => {}} loading={true} />
					<Tile title='' image='' onClick={() => {}} loading={true} />
					<Tile title='' image='' onClick={() => {}} loading={true} />
				</div>
			) : ressource ? (
				<>
					<div className={style.grid}>
						{ressource.map((item: any, index: number) => (
							<Tile
								key={item.id}
								title={item.title.rendered}
								image={getFeaturedImageUrl(item)}
								onClick={() => openPost(item)}
							/>
						))}
					</div>
					{canLoadMore && (
						<Quicklink
							title={i18next.t('news.loadMore')}
							onClick={() => loadMore()}
							loading={isLoadingMore}
						/>
					)}
				</>
			) : (
				<NothingHere />
			)}
		</PullToRefresh>
	);
};
