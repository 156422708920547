import classNames from 'classnames';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	MainMenu,
	MenuItem,
	LegalMenu,
	MenuType,
} from '../../definitions/menu';
import style from './menu.module.scss';
import {
	setCurrentMenuItemAction,
	switchMenuStateAction,
} from '../../redux/actions/navigation';
import { ApplicationState } from '../../redux/types/state';
import { getMenuTitle } from '../../utils/pageTitle';
import { navigate } from '../../utils/navigation';
import { DevelopedBy } from '../developedBy/developedBy';
import logo from '../../assets/logo.svg';

interface MenuProps {}

export const Menu: React.FunctionComponent<MenuProps> = (props: MenuProps) => {
	const { showMenu, currentMenuItem, currentMenuItemType } = useSelector(
		(state: ApplicationState) => state.navigation
	);
	const dispatch = useDispatch();

	const onMenuItemClick = useCallback(
		(item: MenuItem, index: number, menuType: MenuType) => {
			dispatch(setCurrentMenuItemAction(index, menuType));
			dispatch(switchMenuStateAction());
			navigate(item.target);
		},
		[]
	);

	return (
		<div
			className={classNames(style.wrapper, {
				[style.wrapper__show]: showMenu,
			})}>
			<div>
				<div className={style.header}>
					<img
						src={logo}
						className={style.logo}
						alt='TC Schönbusch Aschaffenburg e.V.'
					/>
				</div>
				<ul className={classNames(style.container, style.main)}>
					{MainMenu.map((item: MenuItem, index: number) => (
						<li
							key={`main-${item.titleId}-index`}
							className={classNames(style.item, {
								[style.active]:
									currentMenuItemType === MenuType.MainMenu &&
									currentMenuItem === index,
							})}
							onClick={() => onMenuItemClick(item, index, MenuType.MainMenu)}>
							{getMenuTitle(item)}
						</li>
					))}
				</ul>
			</div>

			<div>
				<DevelopedBy />
				<ul className={classNames(style.container, style.legal)}>
					{LegalMenu.map((item: MenuItem, index: number) => (
						<li
							key={`legal-${item.titleId}-index`}
							className={classNames(style.item, {
								[style.active]:
									currentMenuItemType === MenuType.LegalMenu &&
									currentMenuItem === index,
							})}
							onClick={() => onMenuItemClick(item, index, MenuType.LegalMenu)}>
							{getMenuTitle(item)}
						</li>
					))}
				</ul>
			</div>
		</div>
	);
};
