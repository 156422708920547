import { combineReducers, compose, createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { navigationReducer } from './reducers/navigation';
import { settingsReducer } from './reducers/settings';
import createSagaMiddleware from 'redux-saga';

import { appSaga } from './sagas';
import { postReducer } from './reducers/post';

const developmentMode = process.env.NODE_ENV === 'development';
const combinedReducer = combineReducers({
	navigation: navigationReducer,
	settings: settingsReducer,
	post: postReducer,
});
const sagaMiddleware = createSagaMiddleware();
const composeEnhancers = developmentMode ? composeWithDevTools({}) : compose;

export const store = createStore(
	combinedReducer,
	composeEnhancers(applyMiddleware(sagaMiddleware))
);

sagaMiddleware.run(appSaga);
