import produce from 'immer';
import { PostState } from '../types/state';
import { PostActions } from '../actions/post';

export const initPostState: PostState = {
	post: undefined,
	isOpen: false,
};

export const postReducer = (
	state: PostState = initPostState,
	action: PostActions
) => {
	switch (action.type) {
		case 'openPostAction':
			return produce(state, (draft: PostState) => {
				draft.post = action.payload.post;
				draft.isOpen = true;
			});
		case 'closePostAction':
			return produce(state, (draft: PostState) => {
				draft.isOpen = false;
			});
		default:
			return state;
	}
};
