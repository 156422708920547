export enum RequestType {
	Post = 'posts',
	Page = 'pages',
	Event = 'event',
}

export enum PageIdentifiers {
	Imprint = 266,
	GDPR = 264,
	School = 376,
	MenTeams = 322,
	WomenTeams = 708,
	YouthTeams = 162,
}

const wpApiUrl = process.env.REACT_APP_WEBPAGE_HOST;
export const sendHomepageRequest = (
	type: RequestType,
	ressource?: number,
	limit?: number,
	category?: number
) => {
	let options = limit ? `&per_page=${limit}` : '';
	options += category ? `&categories=${category}` : '';
	return sendGetRequest(
		`${wpApiUrl}${type}/${ressource ?? ''}?_embed${options}`
	);
};

export const sendGetRequest = (url: string) => {
	return fetch(url, {
		method: 'GET',
	});
};
