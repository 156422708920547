import { Plugins } from '@capacitor/core';
const { Toast } = Plugins;

export enum NotificationType {
	Info = 'info',
	Success = 'success',
	Warning = 'warning',
	Error = 'error',
}

export const notification = (type: NotificationType, message: string) => {
	Toast.show({ text: message, position: 'top', duration: 'long' });
};
