import produce from 'immer';
import { NavigationActions } from '../actions/navigation';
import { NavigationState } from '../types/state';
import { MenuType } from '../../definitions/menu';

export const initNavigationState: NavigationState = {
	currentMenuItem: 0,
	currentMenuItemType: MenuType.MainMenu,
	showMenu: false,
};

export const navigationReducer = (
	state: NavigationState = initNavigationState,
	action: NavigationActions
) => {
	switch (action.type) {
		case 'setCurrentMenuItemAction':
			return produce(state, (draft: NavigationState) => {
				draft.currentMenuItem = action.payload.index;
				draft.currentMenuItemType = action.payload.menuType;
			});
		case 'switchMenuStateAction':
			return produce(state, (draft: NavigationState) => {
				draft.showMenu = !state.showMenu;
			});
		default:
			return state;
	}
};
