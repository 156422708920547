import { LatLngExpression } from 'leaflet';

export interface MapItem {
	type: MapItemType;
	title: string;
	position: LatLngExpression[];
}

export enum MapItemType {
	Court = 'courts',
	Fitness = 'fitness',
	Fun = 'fun',
	Clubhouse = 'clubhouse',
	Parking = 'parking',
	Tribune = 'tribune',
}

export const mapItems: MapItem[] = [
	{
		type: MapItemType.Court,
		title: 'Platz 1',
		position: [
			[49.9680536, 9.1232993],
			[49.9679918, 9.1230714],
			[49.9683178, 9.1228579],
			[49.9683796, 9.1230859],
		],
	},
	{
		type: MapItemType.Court,
		title: 'Platz 2',
		position: [
			[49.96815, 9.1236553],
			[49.9680893, 9.1234313],
			[49.9684153, 9.1232178],
			[49.968476, 9.1234419],
		],
	},
	{
		type: MapItemType.Court,
		title: 'Platz 3',
		position: [
			[49.968476, 9.1234419],
			[49.9685408, 9.1236809],
			[49.9682148, 9.1238944],
			[49.96815, 9.1236553],
		],
	},
	{
		type: MapItemType.Court,
		title: 'Platz 4',
		position: [
			[49.9685554, 9.1237194],
			[49.9686151, 9.1239382],
			[49.9682901, 9.124155],
			[49.9682304, 9.1239361],
		],
	},
	{
		type: MapItemType.Court,
		title: 'Platz 5',
		position: [
			[49.9686151, 9.1239382],
			[49.9686748, 9.1241571],
			[49.9683498, 9.1243739],
			[49.9682901, 9.124155],
		],
	},
	{
		type: MapItemType.Court,
		title: 'Platz 6',
		position: [
			[49.9687345, 9.1243759],
			[49.9686748, 9.1241571],
			[49.9683498, 9.1243739],
			[49.9684095, 9.1245928],
		],
	},
	{
		type: MapItemType.Court,
		title: 'Platz 7',
		position: [
			[49.9687345, 9.1243759],
			[49.9684095, 9.1245928],
			[49.9684692, 9.1248117],
			[49.9687942, 9.1245947],
		],
	},
	{
		type: MapItemType.Court,
		title: 'Platz 8',
		position: [
			[49.9686665, 9.1226327],
			[49.9687304, 9.1228651],
			[49.9684334, 9.1230626],
			[49.9683695, 9.1228302],
		],
	},
	{
		type: MapItemType.Court,
		title: 'Platz 9',
		position: [
			[49.9687304, 9.1228651],
			[49.9687944, 9.1230975],
			[49.9684973, 9.123295],
			[49.9684334, 9.1230626],
		],
	},
	{
		type: MapItemType.Court,
		title: 'Platz 10',
		position: [
			[49.9687944, 9.1230975],
			[49.9688583, 9.1233299],
			[49.9685612, 9.1235274],
			[49.9684973, 9.123295],
		],
	},
	{
		type: MapItemType.Court,
		title: 'Platz 11',
		position: [
			[49.9688583, 9.1233299],
			[49.9689222, 9.1235623],
			[49.9686251, 9.1237598],
			[49.9685612, 9.1235274],
		],
	},
	{
		type: MapItemType.Court,
		title: 'Platz 12',
		position: [
			[49.9689576, 9.1235645],
			[49.9690237, 9.1238042],
			[49.9687268, 9.1240021],
			[49.9686607, 9.1237625],
		],
	},
	{
		type: MapItemType.Court,
		title: 'Platz 13',
		position: [
			[49.9690237, 9.1238042],
			[49.9690898, 9.1240439],
			[49.9687929, 9.1242418],
			[49.9687268, 9.1240021],
		],
	},
	{
		type: MapItemType.Court,
		title: 'Platz 14',
		position: [
			[49.9690898, 9.1240439],
			[49.969156, 9.1242835],
			[49.968859, 9.1244815],
			[49.9687929, 9.1242418],
		],
	},
	{
		type: MapItemType.Fitness,
		title: 'Fitnessraum',
		position: [
			[49.9687709, 9.1242407],
			[49.968709, 9.1242825],
			[49.9685933, 9.1238582],
			[49.9686537, 9.1238164],
		],
	},
	{
		type: MapItemType.Clubhouse,
		title: 'Viktoriaheim',
		position: [
			[49.9681357, 9.1239744],
			[49.9682126, 9.1242481],
			[49.9681503, 9.1242903],
			[49.9680734, 9.1240167],
		],
	},
	{
		type: MapItemType.Fun,
		title: 'Multicourt',
		position: [
			[49.9684692, 9.1248117],
			[49.9684209, 9.1246346],
			[49.9682755, 9.1247363],
			[49.9683229, 9.1249119],
		],
	},
	{
		type: MapItemType.Clubhouse,
		title: 'Clubheim & Gaststätte',
		position: [
			[49.9679545, 9.1231316],
			[49.9680284, 9.1233915],
			[49.9679132, 9.1234692],
			[49.9678739, 9.1233314],
			[49.967841, 9.1232162],
		],
	},
	{
		type: MapItemType.Fun,
		title: 'Spielplatz',
		position: [
			[49.9678739, 9.1233314],
			[49.9679132, 9.1234692],
			[49.9678708, 9.1234988],
			[49.9678328, 9.123358],
		],
	},
	{
		type: MapItemType.Fun,
		title: 'Low-T-Ball',
		position: [
			[49.9678708, 9.1234988],
			[49.9679132, 9.1234692],
			[49.9679519, 9.1236088],
			[49.9679113, 9.1236383],
		],
	},

	{
		type: MapItemType.Parking,
		title: 'Parkplatz',
		position: [
			[49.9685282, 9.1205975],
			[49.9683098, 9.120746],
			[49.9679819, 9.1195061],
			[49.96776, 9.1196305],
			[49.9677323, 9.1196493],
			[49.9682006, 9.121314],
			[49.9686448, 9.121012],
		],
	},
	{
		type: MapItemType.Tribune,
		title: 'Tribüne',
		position: [
			[49.9683796, 9.1230859],
			[49.9684153, 9.1232178],
			[49.9680893, 9.1234313],
			[49.9680536, 9.1232993],
		],
	},
];
