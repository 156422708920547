import React, { useEffect, useState } from 'react';
import { Tile } from '../../components/tile/tile';
import { Quicklink } from '../../components/quicklink/quicklink';
import { ModuleHeading } from '../../components/moduleHeading/moduleHeading';
import SwiperCore, { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Plugins } from '@capacitor/core';

import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';
import { RequestType } from '../../services/homepageConnect';
import { useHomepageConnect } from '../../services/homepageConnect.hooks';
import { getFeaturedImageUrl } from '../../utils/wpPostUtils';
import { useSelector, useDispatch } from 'react-redux';
import { ApplicationState } from '../../redux/types/state';
import i18next from 'i18next';
import { openUrl } from '../../utils/navigation';
import { EventSlider } from '../../components/eventSlider/eventSlider';
import style from '../responive.module.scss';
import { useCallback } from 'react';
import { openPostAction } from '../../redux/actions/post';
import { WordPressPost } from '../../services/homepageTypes';
import PullToRefresh from 'react-simple-pull-to-refresh';
import { getStoredDataObject, storeDataObject } from '../../utils/storage';

const initialLaunchStorageKey = 'tcsAppInitialLaunch';
const { LocalNotifications, SplashScreen } = Plugins;
SwiperCore.use([Navigation]);

export const HomePage: React.FunctionComponent = () => {
	const {
		showOutdoorBookingQuicklink,
		showIndoorBookingQuicklink,
		outdoorBookingUrl,
		indoorBookingUrl,
	} = useSelector((state: ApplicationState) => state.settings);
	const dispatch = useDispatch();

	const [refreshCounter, setRefreshCounter] = useState(0);

	const {
		ressource: newsRessources,
		getRessource: getNewsSlides,
		isLoading: isNewsLoading,
	} = useHomepageConnect(RequestType.Post, undefined, 5);

	const initialSetupHandler = useCallback(async () => {
		const initialSetup = await getStoredDataObject(initialLaunchStorageKey);
		if (!initialSetup) {
			await storeDataObject(initialLaunchStorageKey, 1);
			LocalNotifications.requestPermission();
		}
	}, []);

	useEffect(() => {
		getNewsSlides();
		initialSetupHandler();
	}, []);

	const openPost = useCallback(
		(post: WordPressPost) => dispatch(openPostAction(post)),
		[]
	);

	useEffect(() => {
		if (!isNewsLoading) {
			SplashScreen.hide();
		}
	}, [isNewsLoading]);

	const handleRefresh = useCallback(async () => {
		let promiseResolver: (value?: unknown) => void;
		const refreshPromise = new Promise((resolve, reject) => {
			promiseResolver = resolve;
		});

		await getNewsSlides();
		setRefreshCounter(refreshCounter + 1);
		promiseResolver!();

		return refreshPromise;
	}, [refreshCounter]);

	return (
		<div className={style.maxWidth}>
			<PullToRefresh onRefresh={handleRefresh}>
				<>
					<Swiper navigation spaceBetween={50} slidesPerView={1}>
						{isNewsLoading ? (
							<Tile title='' image='' onClick={() => {}} loading={true} />
						) : newsRessources ? (
							newsRessources.map((item: any) => (
								<SwiperSlide key={item.id}>
									<Tile
										title={item.title.rendered}
										image={getFeaturedImageUrl(item)}
										onClick={() => openPost(item)}
										isSlider={true}
									/>
								</SwiperSlide>
							))
						) : (
							<SwiperSlide>
								<Tile title={i18next.t('news.noneTitle')} />
							</SwiperSlide>
						)}
					</Swiper>

					<ModuleHeading title={i18next.t('events.title')} />
					<EventSlider refreshCounter={refreshCounter} />
					<ModuleHeading title={i18next.t('quicklinks.title')} />
					{showOutdoorBookingQuicklink === 'true' && (
						<Quicklink
							title={i18next.t('quicklinks.outdoorBooking')}
							onClick={() => openUrl(outdoorBookingUrl)}
							type='tertiary'
						/>
					)}
					{showIndoorBookingQuicklink === 'true' && (
						<Quicklink
							title={i18next.t('quicklinks.indoorBooking')}
							onClick={() => openUrl(indoorBookingUrl)}
							type='primary'
						/>
					)}
				</>
			</PullToRefresh>
		</div>
	);
};
