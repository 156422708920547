import { notification, NotificationType } from '../utils/notification';
import { StrapiMedia } from './apiTypes';
const apiBase = process.env.REACT_APP_API_HOST;

export enum ApiRessource {
	OSAreas = 'os-areas',
	OSVisitors = 'os-visitors',
	OSVisits = 'os-visits',
	Settings = 'settings',
	Events = 'events',
	Teams = 'teams',
	OpeningHours = 'opening-hours',
	People = 'people',
	TrainingSuspensions = 'trainingsuspensions',
}

export interface Filter {
	key: string;
	value: string;
}

export const getApiRessource = async <type>(
	ressource: ApiRessource,
	filters: Filter[],
	sortItems: Filter[]
): Promise<type | undefined> => {
	try {
		let filter = '?';
		for (const filterItem of filters) {
			filter += `${filterItem.key}=${filterItem.value}&`;
		}
		if (sortItems.length > 0) {
			let sort = '';
			let i = 0;
			for (const sortItem of sortItems) {
				i++;
				sort += `${sortItem.key}:${sortItem.value}${
					i < sortItems.length ? ',' : ''
				}`;
			}
			filter += `_sort=${sort}&`;
		}
		const result = await fetch(`${apiBase}/${ressource}${filter}`, {
			method: 'GET',
		});
		const data: type = await result.json();
		if (result.ok) {
			return data;
		}
	} catch (e) {
		notification(
			NotificationType.Error,
			'Die angeforderte Ressource konnte nicht geladen werden'
		);
	}
	return undefined;
};

export const postApiRessource = async <type>(
	ressource: ApiRessource,
	body: any
): Promise<type | undefined> => {
	try {
		const result = await fetch(`${apiBase}/${ressource}`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(body),
		});
		const data: type = await result.json();
		if (result.ok) {
			return data;
		}
	} catch (e) {
		notification(
			NotificationType.Error,
			'Die angeforderte Ressource konnte nicht erstellt werden'
		);
	}
	return undefined;
};

export const getImageUrl = (media: StrapiMedia) => {
	const url = media?.formats?.medium?.url;
	return url ? `${apiBase}${url}` : undefined;
};
