import React, { useMemo, useEffect } from 'react';
import { EventTile } from '../../components/eventTile/eventTile';
import SwiperCore, { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';
import { useApi } from '../../services/apiConnector.hooks';
import { Event } from '../../services/apiTypes';
import { ApiRessource } from '../../services/apiConnector';
import dayjs from 'dayjs';
import i18next from 'i18next';
import { openUrl } from '../../utils/navigation';

SwiperCore.use([Navigation]);

interface EventSliderProps {
	category?: string;
	refreshCounter?: number;
}

export const EventSlider: React.FunctionComponent<EventSliderProps> = (
	props: EventSliderProps
) => {
	const filter = useMemo(() => {
		const result = [
			{ key: 'ende_gt', value: dayjs().add(-1, 'day').format('YYYY-MM-DD') },
		];
		if (props.category) {
			result.push({ key: 'kategorie', value: props.category });
		}
		return result;
	}, [props.category]);

	const order = useMemo(() => [{ key: 'beginn', value: 'ASC' }], []);

	const {
		ressource: eventRessources,
		isLoading: isEventLoading,
		refresh: refreshEvents,
	} = useApi<Event[]>(ApiRessource.Events, filter, order);

	useEffect(() => {
		refreshEvents();
	}, [props.refreshCounter]);

	return isEventLoading ? (
		<EventTile title='' subtitle='' loading={true} />
	) : (
		<Swiper navigation spaceBetween={50} slidesPerView={1}>
			{eventRessources && eventRessources.length > 0 ? (
				eventRessources.map((item: Event) => (
					<SwiperSlide key={item.id}>
						<EventTile
							title={item.titel}
							subtitle={`${dayjs(item.beginn).format('DD.MM.YYYY - HH:mm')}`}
							link={item.link}
							onClick={() => item.link && openUrl(item.link)}
						/>
					</SwiperSlide>
				))
			) : (
				<SwiperSlide>
					<EventTile
						title={i18next.t('events.none')}
						subtitle={i18next.t('events.noneSub')}
					/>
				</SwiperSlide>
			)}
		</Swiper>
	);
};
