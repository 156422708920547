import { Action } from 'redux';
import { SettingsState } from '../types/state';

interface SetSettingsAction extends Action {
	type: 'setSettingsAction';
	payload: {
		[key: string]: string;
	};
}

export const setSettingsAction = (
	payload: SettingsState
): SetSettingsAction => ({
	type: 'setSettingsAction',
	payload,
});

export type SettingsActions = SetSettingsAction;
