import { useCallback, useState } from 'react';
import { notification, NotificationType } from '../utils/notification';
import { getNextPage } from '../utils/wpPostUtils';
import { sendGetRequest } from './homepageConnect';
import {
	RequestType,
	PageIdentifiers,
	sendHomepageRequest,
} from './homepageConnect';

export const useHomepageConnect = (
	type: RequestType,
	ressource?: PageIdentifiers | number,
	limit?: number,
	category?: number
) => {
	const [isLoading, setIsLoading] = useState(false);
	const [isLoadingMore, setIsLoadingMore] = useState(false);
	const [result, setResult] = useState<any>(undefined);
	const [nextPage, setNextPage] = useState<string | undefined>(undefined);

	const getRessource = useCallback(async () => {
		setIsLoading(true);
		try {
			const response = await sendHomepageRequest(
				type,
				ressource,
				limit,
				category
			);
			const nextPage = getNextPage(response.headers.get('Link'));
			setNextPage(nextPage);
			const data = await response.json();
			if (response.ok) {
				setResult(data);
			}
		} catch (e) {
			console.error(e);
			notification(
				NotificationType.Error,
				'Die angeforderte Homepage-Ressource konnte nicht geladen werden'
			);
		} finally {
			setIsLoading(false);
		}
	}, [result, type, ressource, limit, category]);

	const loadMore = useCallback(async () => {
		setIsLoadingMore(true);
		try {
			if (nextPage) {
				const response = await sendGetRequest(nextPage);
				const nextPageHeader = getNextPage(response.headers.get('Link'));
				setNextPage(nextPageHeader);
				const data = await response.json();
				if (response.ok) {
					setResult([...result, ...data]);
				}
				setIsLoadingMore(false);
			}
		} catch (e) {
			notification(
				NotificationType.Error,
				'Die angeforderte Homepage-Ressource konnte nicht geladen werden'
			);
		} finally {
			setIsLoadingMore(false);
		}
	}, [nextPage, result]);

	return {
		ressource: result,
		isLoading,
		isLoadingMore,
		getRessource,
		canLoadMore: !!nextPage,
		loadMore,
	};
};
