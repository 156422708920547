import React, { useCallback } from 'react';
import style from './siteMap.module.scss';
import { MapContainer, TileLayer, Popup, Polygon } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { useParams } from 'react-router';
import { mapItems, MapItemType } from '../../definitions/map';

export const SiteMap: React.FunctionComponent = () => {
	const { category } = useParams<{ category?: MapItemType }>();

	const getOptions = useCallback(
		(type: MapItemType) => {
			const inactiveColor = { color: '#e6e6e6' };
			switch (type) {
				case MapItemType.Court:
					return !category || category === MapItemType.Court
						? { color: '#cc071e' }
						: inactiveColor;
				case MapItemType.Clubhouse:
					return !category || category === MapItemType.Clubhouse
						? { color: '#0085cd' }
						: inactiveColor;
				case MapItemType.Fitness:
					return !category || category === MapItemType.Fitness
						? { color: '#0085cd' }
						: inactiveColor;
				case MapItemType.Parking:
					return !category || category === MapItemType.Parking
						? { color: '#0085cd' }
						: inactiveColor;

				case MapItemType.Fun:
					return !category || category === MapItemType.Fun
						? { color: '#fdc300' }
						: inactiveColor;
				default:
					return { color: '#034890' };
			}
		},
		[category]
	);

	return (
		<MapContainer
			center={[49.96825735051366, 9.123768775588104]}
			zoom={21}
			scrollWheelZoom={false}
			zoomControl={false}
			className={style.map}>
			<TileLayer
				attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
				url='https://{s}.tile.openstreetmap.de/{z}/{x}/{y}.png'
			/>
			{mapItems.map((item) => (
				<Polygon
					positions={item.position}
					pathOptions={getOptions(item.type)}
					key={item.title}>
					<Popup className={style.popup}>{item.title}</Popup>
				</Polygon>
			))}
		</MapContainer>
	);
};
