import React, { useEffect } from 'react';
import { ArticleContent } from '../../components/articleContent/articleContent';
import { NothingHere } from '../../components/nothingHere/nothingHere';
import { RequestType, PageIdentifiers } from '../../services/homepageConnect';
import { useHomepageConnect } from '../../services/homepageConnect.hooks';
import style from '../responive.module.scss';

interface DefaultPageProps {
	pageIdentifier: PageIdentifiers;
}

export const DefaultPage: React.FunctionComponent<DefaultPageProps> = (
	props: DefaultPageProps
) => {
	const { ressource, isLoading, getRessource } = useHomepageConnect(
		RequestType.Page,
		props.pageIdentifier
	);

	useEffect(() => {
		getRessource();
	}, [props.pageIdentifier]);

	return (
		<div className={style.maxWidth}>
			{isLoading ? (
				<ArticleContent content='' loading={true} />
			) : !ressource ? (
				<NothingHere />
			) : (
				<ArticleContent content={ressource.content.rendered} />
			)}
		</div>
	);
};
