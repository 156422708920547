import React from 'react';
import style from './eventTile.module.scss';
import classNames from 'classnames';

interface EventTileProps {
	title: string;
	subtitle: string;
	loading?: boolean;
	link?: string | null;
	onClick?: () => void;
}

export const EventTile: React.FunctionComponent<EventTileProps> = (
	props: EventTileProps
) => {
	return (
		<div
			className={classNames(style.container, {
				[style.loading]: props.loading,
			})}
			onClick={props.onClick}>
			<span className={style.subHeading}>{props.subtitle}</span>
			<span className={style.heading}>{props.title}</span>
		</div>
	);
};
