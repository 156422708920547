import React, { useEffect } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router';
import { Menu } from './components/menu/menu';
import { PageWrapper } from './components/pageWrapper/pageWrapper';
import { HomePage } from './pages/home/home';
import { NewsPage } from './pages/news/news';
import { TeamsPage } from './pages/teams/teams';
import { SiteMap } from './pages/anlage/siteMap';
import { useDispatch } from 'react-redux';
import { DefaultPage } from './pages/default/default';
import { PageIdentifiers } from './services/homepageConnect';
import { initializeApplicationAction } from './redux/actions/general';
import { TennisschulePage } from './pages/tennisschule/tennisschule';
import { RestaurantPage } from './pages/restaurant/restaurant';
import { Plugins, StatusBarStyle } from '@capacitor/core';
import { Post } from './components/post/post';

const { StatusBar } = Plugins;

const App: React.FunctionComponent = () => {
	const location = useLocation();
	const dispatch = useDispatch();

	useEffect(() => {
		StatusBar.setStyle({ style: StatusBarStyle.Dark });
		StatusBar.setBackgroundColor({ color: '#034890' });
		StatusBar.setOverlaysWebView({ overlay: true });
		StatusBar.show();
		dispatch(initializeApplicationAction(location.pathname));
	}, []);

	return (
		<>
			<Menu />
			<PageWrapper title='Home'>
				<Switch>
					<Route path='/home' component={HomePage} />
					<Route path='/news/:category?' component={NewsPage} />
					<Route path='/mannschaften/:category?' component={TeamsPage} />
					<Route path='/anlage/:category?' component={SiteMap} />
					<Route path='/tennisschule' component={TennisschulePage} />
					<Route path='/kontakt' component={NewsPage} />
					<Route path='/restaurant' component={RestaurantPage} />
					<Route path='/datenschutz'>
						<DefaultPage pageIdentifier={PageIdentifiers.GDPR} />
					</Route>
					<Route path='/impressum'>
						<DefaultPage pageIdentifier={PageIdentifiers.Imprint} />
					</Route>
					<Route>
						<Redirect to='/home' />
					</Route>
				</Switch>
			</PageWrapper>
			<Post />
		</>
	);
};

export default App;
