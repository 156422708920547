import React from 'react';
import style from './tile.module.scss';
import logo from '../../assets/logo.svg';
import classNames from 'classnames';

interface TileProps {
	title: string;
	subtitle?: string;
	children?: React.ReactNode | React.ReactNodeArray;
	image?: string;
	hideImage?: boolean;
	onClick?: () => void;
	loading?: boolean;
	isSlider?: boolean;
}

export const Tile: React.FunctionComponent<TileProps> = (props: TileProps) => {
	return (
		<div
			className={classNames(style.container, {
				[style.loading]: props.loading,
				[style.container__slider]: props.isSlider,
			})}
			onClick={props.onClick}>
			{!props.hideImage &&
				(!props.loading ? (
					<img
						src={props.image ?? logo}
						className={classNames(style.image, { [style.logo]: !props.image })}
						alt={props.title}
					/>
				) : (
					<div className={style.image} />
				))}
			<span
				className={style.heading}
				dangerouslySetInnerHTML={{ __html: props.title }}
			/>
			{props.subtitle && (
				<span
					className={style.subtitle}
					dangerouslySetInnerHTML={{ __html: props.subtitle }}
				/>
			)}
			<div className={style.content}>{props.children}</div>
		</div>
	);
};
