import { Action } from 'redux';

export interface InitializeApplicationAction extends Action {
	type: 'initializeApplicationAction';
	payload: {
		pathname: string;
	};
}

export const initializeApplicationAction = (
	pathname: string
): InitializeApplicationAction => ({
	type: 'initializeApplicationAction',
	payload: {
		pathname,
	},
});
