import React, { useMemo } from 'react';
import classNames from 'classnames';
import style from './post.module.scss';
import { getFeaturedImageUrl } from '../../utils/wpPostUtils';
import logo from '../../assets/logo.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { closePostAction } from '../../redux/actions/post';
import { ApplicationState } from '../../redux/types/state';

export const Post: React.FunctionComponent = () => {
	const post = useSelector((state: ApplicationState) => state.post);
	const dispatch = useDispatch();

	const featuredImage = useMemo(
		() => (post.post ? getFeaturedImageUrl(post.post) : ''),
		[post.post]
	);

	const title = useMemo(() => (post.post ? post.post.title.rendered : ''), [
		post.post,
	]);

	const content = useMemo(() => post.post?.content.rendered, [post.post]);

	return (
		<div
			className={classNames(style.container, {
				[style.container__show]: post.isOpen,
				[style.container__noContent]: content === undefined,
			})}>
			<FontAwesomeIcon
				icon={faTimes}
				className={style.closeIcon}
				onClick={() => dispatch(closePostAction())}
			/>
			{content && (
				<>
					<img
						src={featuredImage ?? logo}
						className={classNames(style.image, {
							[style.logo]: !featuredImage,
						})}
						alt={title}
					/>
					<h2
						className={style.heading}
						dangerouslySetInnerHTML={{ __html: title }}
					/>
					<div
						className={style.content}
						dangerouslySetInnerHTML={{ __html: content }}
					/>
				</>
			)}
		</div>
	);
};
