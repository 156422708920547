import { Action } from 'redux';
import { MenuType } from '../../definitions/menu';

interface SetCurrentMenuItemAction extends Action {
	type: 'setCurrentMenuItemAction';
	payload: {
		index: number;
		menuType: MenuType;
	};
}

export const setCurrentMenuItemAction = (
	index: number,
	menuType: MenuType
): SetCurrentMenuItemAction => ({
	type: 'setCurrentMenuItemAction',
	payload: {
		index,
		menuType,
	},
});

interface SwitchMenuStateAction extends Action {
	type: 'switchMenuStateAction';
}

export const switchMenuStateAction = (): SwitchMenuStateAction => ({
	type: 'switchMenuStateAction',
});

export type NavigationActions =
	| SetCurrentMenuItemAction
	| SwitchMenuStateAction;
