import { useCallback, useState, useEffect } from 'react';
import { ApiRessource, Filter, getApiRessource } from './apiConnector';

export const useApi = <type>(
	ressource: ApiRessource,
	filters?: Filter[],
	sortItems?: Filter[]
) => {
	const [isLoading, setIsLoading] = useState(true);
	const [result, setResult] = useState<type | undefined>(undefined);

	const getRessource = useCallback(async () => {
		setIsLoading(true);
		setResult(
			await getApiRessource<type>(ressource, filters ?? [], sortItems ?? [])
		);
		setIsLoading(false);
	}, [ressource, filters]);

	useEffect(() => {
		getRessource();
	}, [ressource, filters]);

	return { ressource: result, isLoading, refresh: getRessource };
};
