import { Action } from 'redux';
import { WordPressPost } from '../../services/homepageTypes';

interface OpenPostAction extends Action {
	type: 'openPostAction';
	payload: {
		post: WordPressPost;
	};
}

export const openPostAction = (post: WordPressPost): OpenPostAction => ({
	type: 'openPostAction',
	payload: {
		post,
	},
});

interface ClosePostAction extends Action {
	type: 'closePostAction';
}

export const closePostAction = (): ClosePostAction => ({
	type: 'closePostAction',
});

export type PostActions = OpenPostAction | ClosePostAction;
